<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Chick Transfer Entry
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-data-entry-list" method="post" @submit="addChickTransfer">
				<b-card>
					<b-row>
						<!-- <b-col md="6">
							<b-form-group>
								<template v-slot:label> Date <span class="text-danger">*</span> </template>
								<b-form-datepicker
									name="Date "
									v-model="FormData.purchase_date"
									menu-class="w-100"
									calendar-width="100%"
									class="mb-1"
								/>
							</b-form-group>
						</b-col> -->
						<!-- <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Water Given Yesterday (Ltr)<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Water Given Yesterday (Ltr)"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.yesterday_water"
                      placeholder="Water Given Yesterday (Ltr)"
                      type="text"
                      name="yesterday_water"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col> -->
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Current Flock<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<b-form-input
										placeholder="Flock Id"
										type="text"
										id="flock_id"
										name="flockId"
										v-model="flockId"
										disabled
									/>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Birds Available<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Birds Available" rules="required">
										<b-form-input
											placeholder="Birds Available"
											type="text"
											id="quantity"
											name="birds_available"
											v-model="flockDetails.alive_birds"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Shed<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Select Shed" rules="required">
										<v-select
											v-model="FormData.shed_selected"
											:options="shedList"
											label="shed_name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Chick Transfer Quantity <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="No. of Chick Placed "
										:rules="{
											required: true,
											max_value: flockDetails.alive_birds,
											regex: RegExp(/^([0-9]+)$/),
										}"
									>
										<b-form-input
											v-model="FormData.chick_placed"
											placeholder="No. of Chick Placed "
											type="text"
											name="chick_placed"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Flock Name <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Flock Name "
										:rules="{
											required: true,
										}"
									>
										<b-form-input
											v-model="FormData.flock_name"
											placeholder="Flock Name"
											type="text"
											name="flock_name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>

						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Transfer </b-button>
							<b-modal v-model="isConfirmationVisible" title="Confirmation" ok-title="Confirm" @ok="handleConfirmation">
								Are you sure you want to transfer?
							</b-modal>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";
import { BButton, BModal } from "bootstrap-vue";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
		BButton,
		BModal,
	},

	data() {
		return {
			required,
			water_list: [],
			selectFeedList: "",
			flockInfo: [],
			flockDetails: [],
			shedList: [],
			FormData: {
				shed_selected: null,
			},
			isConfirmationVisible: false,
		};
	},
	created: function () {
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
		this.getFlockDetails();
		this.getShedList();
	},
	methods: {
		goBack() {
			this.$router.push(`/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`);
		},
		showConfirmation() {
			this.isConfirmationVisible = true;
		},

		getFlockDetails() {
			axiosIns
				.get(`web/flock-details/${this.flockId}`)
				.then((response) => {
					this.flockDetails = response.data.flock_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getShedList() {
			axiosIns
				.get(`web/farm/${this.farmId}/shed-available`)
				.then((response) => {
					const shed = response.data.sheds;
					this.shedList = Object.values(shed);
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addChickTransfer(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("alive_birds", this.FormData.chick_placed);
					data.append("flock_name", this.FormData.flock_name);
					const shed = this.FormData.shed_selected;
					axiosIns
						.post(`web/farm/${this.farmId}/shed/${shed}/flock-transfer/${this.flockId}`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Chick Transferred Successfully `,
								},
							});
							this.$router.push({
								path: `/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`,
							});
						})
						.catch((error) => {
							debugger;
							const data = error.response.data.message;
							data, "data";
							let arr = Object.values(data).flat().toString(",");
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
